import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import ThemeBox from "../../components/bricks/ThemeBox";
import {themeBright, themeDark, themePurple} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";

const VsTrello = ({location}) => (
  <DefaultLayout
    title="Codecks vs Trello"
    description="Trello's simplicity is great when starting. But it comes at a cost when projects are growing. Here's a comparison."
    location={location}
  >
    <Hero
      size="sm"
      title="Codecks vs Trello"
      subline="Using Trello for game development is a common choice for beginning teams. But it quickly falls short when used heavily in multi-disciplinary teams. Here's why Codecks is the better trello for game devs."
    />
    <ThemeBox theme={themeBright} withBorder title="The interface">
      <ThemedSingleFeature childAsDescription img="trello-1.png" title="Card-based tasks">
        Both Trello and Codecks are using cards as a vehicle for your tasks. This is not a
        coincidence. Cards have a great form factor when it comes to radiating a lot of information
        at once. They also allow to fill especially bigger screens effectively when comparing it to
        more table oriented alternatives. It's also a joy to drag them around because they feel
        close enough to their real-world counterparts.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="trello-2.png"
        imgRight
        title="Horizontal vs vertical layout"
      >
        When it comes to arranging cards, there's a big difference. Trello lists cards vertically
        within lists whereas Codecks lays them out in horizontal swim lanes which allow to contain
        more than one row of cards. While Trello's lists allow to only display a couple of cards
        before you need to scroll, Codecks' swim lanes allow to display 50+ cards at once even on
        medium sized screens.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="trello-3.png"
        title="Collectible card games as inspiration"
      >
        To ensure that you can immediately grasp the state of your project at a glance, we went to
        great lengths to ensure that our card representation just feels right. We took a lot of
        design hints from collectible card games to ensure that they radiate the right kind of
        information. This way a blocked card or a card requiring a review, will be immediately
        visible.
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themeDark} withBorder title="Managing projects">
      <ThemedSingleFeature childAsDescription img="trello-4.png">
        Once your project grows, you'll realize that Trello lacks the right primitives to organize
        your work. There's only two ways to categorize a card: by the lane it's in and by applying
        labels. While simple, this is extremely limiting when you'd like to categorize your work by
        e.g. type, priority, effort and progress.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="cards-3.png"
        imgRight
        title="Made for project management"
      >
        Codecks has been designed as a project management tool from the start. Each card can be
        assigned a priority, effort, tags, milestones (and of course an owner). All these properties
        are optional, so it's up to you and your project to decide what granularity is the right
        fit.
      </ThemedSingleFeature>
      <ThemedSingleFeature childAsDescription img="trello-5.png" title="Workflow as part of a card">
        Rather than using Kanban-style lanes for indicating a card's progress, Codecks includes this
        information within each card. You'll quickly learn that a gray or brown card hasn't been
        started yet, while blue means "in progress" and green cards are "done". There's also
        distinct colors for blocked cards and cards in review.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="decks-3.png"
        imgRight
        title="Group your work using decks"
      >
        Codecks uses decks as container for cards. This allows you to create a deck for each feature
        type to slice your backlog into manageable chunks. Hundreds of cards can be effectively
        managed this way and if you feel you need an even higher level of abstraction, Codecks
        offers a great multi-project support allowing you to manage several projects in parallel or
        separately.
      </ThemedSingleFeature>
      <ThemedSingleFeature childAsDescription img="milestones-4.png" title="Batteries included">
        Finally, while you can use Trello for gamedev, it has no strong opinion and thus no strong
        support for following any specific project management philosophy. As Codecks has been
        developed and refined while being used for the creation of several games, we have been able
        to capture a lot of recurring patterns. To just name one example, Codecks allows to create
        Milestones which are a great way to manage recurring sprints and releases.
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themeBright} withBorder title="Let's have a conversation about conversations">
      <ThemedSingleFeature childAsDescription img="conversations-notifications-1.png">
        Having productive conversations is a corner-piece of any successful project managed by more
        than one person. This is why we focused a lot of our attention to nail this part of the
        process. We have come up with a unique conversation system that is aimed at preventing loose
        ends.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="conversations-1.png"
        imgRight
        title="Each idea deserves its own thread"
      >
        All too often it happens that people get notified of a comment but are not in a position to
        reply right away. Hardly any tool approaches this common problem effectively and Trello is
        no exception. Codecks employs a few ideas to ensure that the no comment gets lost. First
        off, every conversation happens inside a thread. This not only allows to have separate
        discussions on a single cards, but also allows you to define who is part of a conversation
        and whether this conversation can be closed.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="conversations-4.png"
        title="Who takes part and did we come to a conclusion?"
      >
        When creating a conversation, you may select the participants either from a list of common
        conversation partners or by simply mentioning them in your message. Every participant will
        get notified of this conversation and Codecks ensures that the conversation comes to a
        conclusion by regularly reminding all participants of any open conversation. If people feel
        like they can't contribute any more, they can always opt out.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="conversations-2.png"
        imgRight
        title="Highlight important conversations"
      >
        Even more interestingly, two workflow states are directly bound to conversations. Both,
        blocking a card and reviews, are initiated by starting a conversation. The card will remain
        in that highly visible state and send regular reminders to all participants until the
        conversation has been marked as resolved.
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themePurple} withBorder title="But Trello is free!?">
      <ThemedSingleFeature childAsDescription img="trello-6.png">
        For personal use it still is. But in March 2019 Trello rolled out a new pricing policy to
        allow only up to 10 boards for a team. So depending on the way you manage an ever growing
        backlog, it's not unrealistic that you need to pay at some point. And why wouldn't you. In
        our opinion, it's only fair to be paying for a service that provides a considerable value to
        you and your team.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="trello-7.png"
        imgRight
        title="Doing it the indie way"
      >
        As opposed to most competitors, Codecks never got any external funding. It has maintained
        its freedom from any outside interests by bootstrapping and considerable personal
        investments in a true indie developer spirit. This is also why we are much more dependent on
        being paying paid for the value we provide, while still trying to be as affordable as
        possible.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="trello-8.png"
        title="Let's see what Codecks can do for you"
      >
        We have created a product born from playful design and gamedev-honed expertise that goes far
        beyond just being a good trello alternative. If you're looking to create your dream game
        project, you need the tools to support you properly. Why settle for something less?
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox
      id="importer"
      theme={themeBright}
      withBorder
      title="Automatically import your Trello boards to Codecks"
    >
      <ThemedSingleFeature childAsDescription img="trello-importer.png">
        If you have some work on Trello already that you'd like to transfer, we've created a handy
        tool for you. Our importer allows you to add all cards of a board into a Codecks project.
        Lists will become decks, and cards, ...well ...stay cards.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        title="Get started"
        img="trello-import-1.png"
        imgRight
      >
        Once you've set up your Codecks Account, open your Organization Settings and find the Trello
        Importer. Click the <b>Get Started</b> Button to authenticate with Trello.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        title="Select a board and pick your target project"
        img="trello-import-2.png"
      >
        You will then be able to pick a Trello board of yours. Now choose whether you want to add
        your cards to an existing project or create a new one.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        title="Confirm and go!"
        img="trello-import-3.png"
        imgRight
      >
        The next screen allow you to decide whether you want to copy your attachments (up to 100MB
        in total) and whether you want to map Trello users to Codecks users. It might make sense to
        invite your team members before performing this step!
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default VsTrello;
